import { AxiosInstance } from "axios";
import { RenderTree } from "promodx-component-library";
import { KnotType } from "./Enum";

/**
 * Fetches all knots for an admin user, (s)he gets the whole tree
 *
 * @param axios
 */
export const getKnotsFromServerForAdmin = async (
  axios: AxiosInstance
): Promise<RenderTree[]> => {
  return axios.get("/knot/admin/").then((result) => result.data);
};

/**
 * Fetches user knots
 *
 * @param groupId
 * @param axios
 */
export const getKnotsFromServerForUser = async (
  groupId: string,
  axios: AxiosInstance
): Promise<RenderTree[]> => {
  return axios
    .get("/knot/user/", { params: { groupId: groupId } })
    .then((result) => result.data);
};

/**
 * Generates an empty RenderTree Knot
 */
export const generateEmptyRenderTree = (): RenderTree => ({
  id: "",
  knotType: KnotType.WERK,
  name: "",
  description: "",
  dependsOn: "",
});
