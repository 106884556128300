import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./styles/index.scss";

import App from "./pages/App";
import "./i18n";
import { KeycloakProvider } from "@react-keycloak/web";
import keycloak from "./utils/Keycloak";
import { KeycloakInitOptions } from "keycloak-js";
import { LoaderComponent } from "promodx-component-library";

const keycloakProviderInitConfig: KeycloakInitOptions = {
  onLoad: "check-sso",
  enableLogging: true,
  silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
};

const eventLogger = (event: unknown, error: unknown) => {
  // console.log("onKeycloakEvent", event, error);
};

const tokenLogger = (tokens: unknown) => {
  // console.log("onKeycloakTokens", tokens);
};

ReactDOM.render(
  <React.StrictMode>
    <KeycloakProvider
      keycloak={keycloak}
      LoadingComponent={<LoaderComponent />}
      onEvent={eventLogger}
      onTokens={tokenLogger}
      initConfig={keycloakProviderInitConfig}
    >
      <App />
    </KeycloakProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
