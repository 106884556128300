import Keycloak from "keycloak-js";

// the central keycloak config
const keycloakConfig = {
  realm: "promodx",
  url: process.env.REACT_APP_PROMODX_KEYCLOAK_URL,
  clientId: "app",
};

// central keycloak instance
const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
