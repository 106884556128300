import { AxiosInstance } from "axios";
import { RenderTree } from "promodx-component-library";
import {
  LinieMonitor,
  LinieReifegradStationAmountWrapper,
  LinieReifegradWrapper,
  Station,
  StationBarData,
} from "./Interface";

/**
 * loads all stations for a line
 *
 * @param axios
 * @param linieId
 */
export const getAllStationEGsForLinie = async (
  axios: AxiosInstance,
  linieId: string
): Promise<Station[]> => {
  return axios
    .get("/station/monitor/station/linie/", { params: { linieId: linieId } })
    .then((allStationResponse) => allStationResponse.data);
};

/**
 * Helper method to load simplified station instances for the given ids.
 *
 * @param listOfIds The station ids to load
 * @param axios the axios instance
 */
export const getStationEgsForUser = async (
  listOfIds: string[],
  axios: AxiosInstance
): Promise<Station[]> => {
  if (listOfIds.length > 0) {
    return axios
      .post("/station/monitor/station/", listOfIds)
      .then((result) => result.data);
  } else {
    return [];
  }
};

/**
 * Helper method to extract the station ids the user is activated for.
 *
 * @param loadedTree the fetched knot tree for the active user
 */
export const extractStationIdsFromKnots = (
  loadedTree: RenderTree[],
  linieId: string
): string[] => {
  let stationIds: string[] = [];
  if (loadedTree) {
    let werk = loadedTree[0].children!;
    let center = werk.flatMap((werk) => werk.children);
    let abteilung = center.flatMap((center) => center?.children);
    let kostenstelle = abteilung.flatMap((abteilung) => abteilung?.children);
    let linie = kostenstelle.flatMap((kostenstelle) => kostenstelle?.children);
    let filteredLinie = linie.filter((linie) => linie?.id === linieId);
    let station = filteredLinie.flatMap((linie) => linie?.children);
    stationIds = station.flatMap((station) => station?.stationId) as string[];
  }
  return stationIds;
};

/**
 * Calculates the ø EG for the given stations and returns them in
 * the wrapper object.
 *
 * @param stations The stations for display
 */
export const extractBarDataFromStations = (
  stations: Station[]
): StationBarData[] => {
  let resultData: StationBarData[] = [];
  stations.forEach((station) => {
    const totalPrismaEg: number =
      (station.egBetriebszustaende! +
        station.egMaterialFluss! +
        station.egProzessabgrenzung! +
        station.egStationData!) /
      4;
    resultData.push({
      value: Number(totalPrismaEg.toFixed(0)),
      "Station Name": `${station.name} - ${station.oemSupplier}`,
      name: "PRISMA EG",
    });
    const totalZsEg: number =
      (station.egZsDatenfluss! + station.egZsProzesseAndSockets!) / 2;
    resultData.push({
      value: Number(totalZsEg.toFixed(0)),
      "Station Name": `${station.name} - ${station.oemSupplier}`,
      name: "ZS EG",
    });
  });
  return resultData;
};

/**
 * Helper method to map the reifegrad entries of the given
 * LinieMonitor in the wrapper object to display.
 *
 * @param linieMonitor The liniemonitor to display
 */
export const extractReifegradeFromLinieMonitor = (
  linieMonitors: LinieMonitor[]
): {
  stationAmount: LinieReifegradStationAmountWrapper[];
  rgTracking: LinieReifegradWrapper[];
} => {
  let targetObject: {
    stationAmount: LinieReifegradStationAmountWrapper[];
    rgTracking: LinieReifegradWrapper[];
  } = { stationAmount: [], rgTracking: [] };
  let rgResultData: LinieReifegradWrapper[] = [];
  let stationAmountResultData: LinieReifegradStationAmountWrapper[] = [];
  linieMonitors.forEach((linieMonitor) => {
    Object.entries(linieMonitor.reifegradMapping).forEach((prismaRg) => {
      rgResultData.push({
        Datum: new Date(prismaRg[0]).toLocaleDateString(),
        Reifegrad: prismaRg[1],
        name: "PRISMA RG (%)",
      });
      stationAmountResultData.push({
        Datum: new Date(prismaRg[0]).toLocaleDateString(),
        Station: 0,
      });
    });
    Object.entries(linieMonitor.reifegradZsMapping).forEach((zsRg) => {
      rgResultData.push({
        Datum: new Date(zsRg[0]).toLocaleDateString(),
        Reifegrad: zsRg[1],
        name: "ZS RG (%)",
      });
    });
  });
  targetObject.rgTracking = rgResultData;
  linieMonitors.forEach((linieMonitor) => {
    Object.entries(linieMonitor.stationAmountMapping).forEach(
      (stationAmountMappingObject) => {
        stationAmountResultData.find(
          (tempObj) =>
            tempObj.Datum ===
            new Date(stationAmountMappingObject[0]).toLocaleDateString()
        )!.Station = stationAmountMappingObject[1];
      }
    );
  });
  targetObject.stationAmount = stationAmountResultData;
  return targetObject;
};

/**
 * Helper method to extract the highest number of station objects
 * within the loaded linie monitors.
 *
 * @param linieMonitors The loaded liniemonitor objects
 */
export const getHighestStationAmountFromLinieMonitor = (
  linieMonitors: LinieMonitor[]
): number => {
  let targetNumber = 3;
  linieMonitors.forEach((linieMonitor) => {
    Object.entries(linieMonitor.stationAmountMapping).forEach(
      (stationAmountMappingObject) => {
        if (stationAmountMappingObject[1] > targetNumber)
          targetNumber = stationAmountMappingObject[1];
      }
    );
  });
  return targetNumber + 3;
};

/**
 * Helper method to load the liniemonitor data from the server.
 *
 * @param linieId LinieId to load
 * @param axios The axios instance
 */
export const loadLinieMonitor = async (
  linieId: string,
  axios: AxiosInstance
): Promise<LinieMonitor[]> => {
  return axios
    .get("/station/monitor/linie/rg/", { params: { linieId: linieId } })
    .then((linieMonitorResult) => linieMonitorResult.data);
};
