/**
 * Enum for the users language
 */
export enum Language {
  DE = "DE",
  EN = "EN",
}

/**
 * A GroupFunction can be used to finegrain adjust a group with more rights.
 */
export enum GroupFunction {
  STATION_FREEZE = "STATION_FREEZE",
}

export enum KnotType {
  ROOT = "ROOT",
  WERK = "WERK",
  CENTER = "CENTER",
  ABTEILUNG = "ABTEILUNG",
  KOSTENSTELLE = "KOSTENSTELLE",
  LINIE = "LINIE",
  STATION = "STATION",
}

export enum CenterScreenContent {
  INIT_STATION = "INIT_STATION",
  KNOT_DETAIL = "KNOT_DETAIL",
}

/**
 * Types of sources to push back in history
 */
export enum SourceType {
  COMMENTARY = "COMMENTARY",
  SCORECARD = "SCORECARD",
  DEFAULT = "DEFAULT",
}
