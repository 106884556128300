import {
  ColumnChartComponent,
  DualAxisChartComponent,
  GaugeChartComponent,
  LoaderComponent,
  RenderTree,
} from "promodx-component-library";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { RenderTreeContext, UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import {
  LinieMonitor,
  LinieReifegradStationAmountWrapper,
  LinieReifegradWrapper,
  Station,
  StationBarData,
} from "../../utils/Interface";
import {
  extractBarDataFromStations,
  extractReifegradeFromLinieMonitor,
  extractStationIdsFromKnots,
  getAllStationEGsForLinie,
  getHighestStationAmountFromLinieMonitor,
  getStationEgsForUser,
  loadLinieMonitor,
} from "../../utils/PowerWallUtil";
import "./PowerWallStyle.scss";

interface PowerWallProps {
  selectedKnot: RenderTree;
}

const PowerWall: React.FC<PowerWallProps> = ({ selectedKnot }) => {
  const axios = useAxios();
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserContext);
  const { renderTree } = useContext(RenderTreeContext);
  const [stationEgData, setStationEgData] = useState<StationBarData[]>([]);
  const [linieMonitor, setLinieMonitor] = useState<LinieMonitor[]>();
  const [chartData, setChartData] = useState<{
    stationAmount: LinieReifegradStationAmountWrapper[];
    rgTracking: LinieReifegradWrapper[];
  }>();
  const [showNoData, toggleShowNoData] = useState<boolean>(false);
  const [showLoader, toggleLoader] = useState<boolean>(true);

  const loadStations = () => {
    isAdmin
      ? getAllStationEGsForLinie(axios, selectedKnot.id).then(
          (loadedStations: Station[]) =>
            setStationEgData(extractBarDataFromStations(loadedStations))
        )
      : getStationEgsForUser(
          extractStationIdsFromKnots(renderTree, selectedKnot.id),
          axios
        ).then((loadedStations: Station[]) =>
          setStationEgData(extractBarDataFromStations(loadedStations))
        );
  };

  const loadLinieData = () => {
    loadLinieMonitor(selectedKnot.id, axios).then((loadedLinieMonitor) => {
      if (loadedLinieMonitor) {
        setLinieMonitor(loadedLinieMonitor);
      } else {
        toggleShowNoData(true);
      }
      toggleLoader(false);
    });
  };

  useEffect(() => {
    if (!!axios) {
      loadStations();
      loadLinieData();
    }
    // eslint-disable-next-line
  }, [axios]);

  useEffect(() => {
    if (linieMonitor) {
      setChartData(extractReifegradeFromLinieMonitor(linieMonitor));
    }
  }, [linieMonitor]);

  return (
    <div id="powerwall-wrapper">
      {showLoader && (
        <div id="powerwall-loader-wrapper">
          <LoaderComponent />
        </div>
      )}
      {chartData && linieMonitor && (
        <>
          <div
            id="powerwall-linie-monitor-wrapper"
            className={isMobile ? "is-mobile" : ""}
          >
            <div
              id="powerwall-linie-monitor-reifegrad"
              className={isMobile ? "is-mobile" : ""}
            >
              <h3>{t("knotDetail.powerwall.reifegradTitle")}</h3>
              <DualAxisChartComponent
                data1={chartData.stationAmount}
                data2={chartData.rgTracking}
                xName="Datum"
                yNames={["Station", "Reifegrad"]}
                color1="#3d80ba"
                color2="#88c952"
                color3="#C85151"
                multiLine
                maxValue1={getHighestStationAmountFromLinieMonitor(
                  linieMonitor
                )}
                maxValue2={100}
                minValue1={0}
                minValue2={0}
                showDots1
                showDots2
                label1={t("knotDetail.powerwall.stationAmountLabel")}
              />
            </div>
            <div
              id="powerwall-linie-monitor-performance"
              className={isMobile ? "is-mobile" : ""}
            >
              <h3>{t("knotDetail.powerwall.performanceTitle")}</h3>
              <GaugeChartComponent
                percent={
                  linieMonitor[linieMonitor.length - 1]?.liniePerformance || 0
                }
                needleColor={"#8c8c8c"}
              />
              <h3>{t("knotDetail.powerwall.performanceZsTitle")}</h3>
              <GaugeChartComponent
                percent={
                  linieMonitor[linieMonitor.length - 1]?.linieZsPerformance || 0
                }
                needleColor={"#8c8c8c"}
              />
            </div>
          </div>
          <div id="powerwall-linie-monitor-stationeg">
            <h3>{`${t(
              "knotDetail.powerwall.egStationTitle"
            )} ${new Date().toLocaleString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}`}</h3>
            <ColumnChartComponent
              data={stationEgData}
              yName="value"
              xName="Station Name"
              multiValue
              lineColor="#88c952"
              lineColor2="#C85151"
            />
          </div>
        </>
      )}
      {showNoData && <p>{t("knotDetail.powerwall.noData")}</p>}
    </div>
  );
};

export default PowerWall;
