import { useKeycloak } from "@react-keycloak/web";
import i18next from "i18next";
import { LoaderComponent, RenderTree } from "promodx-component-library";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "../styles/App.scss";
import { useAxios } from "../utils/AxiosUtil";
import { KnotType } from "../utils/Enum";
import {
  createEmptyKeycloakUser,
  createEmptyUser,
  Group,
  KeycloakUser,
  User,
} from "../utils/Interface";
import {
  getKnotsFromServerForAdmin,
  getKnotsFromServerForUser,
} from "../utils/KnotUtil";
import { getCurrentUserFromServer } from "../utils/UserUtil";
import DashboardPage from "./DashboardPage";

export const UserContext = React.createContext<KeycloakUser>(
  createEmptyKeycloakUser()
);
export const RenderTreeContext = React.createContext<{
  renderTree: RenderTree[];
  updateRenderTree: Function;
}>({ renderTree: [], updateRenderTree: () => {} });

export const ServerUserContext = React.createContext<{
  user: User;
  userUpdateFunction: Function;
}>({
  user: createEmptyUser(),
  userUpdateFunction: () => {},
});

export const GroupContext = React.createContext<{
  groups: Group[];
  updateGroups: Function;
}>({ groups: [], updateGroups: () => {} });

const App = () => {
  const { keycloak } = useKeycloak();
  const axios = useAxios();
  const [keycloakUser, setKeycloakUser] = useState<KeycloakUser>(
    createEmptyKeycloakUser()
  );
  const [user, setUser] = useState<User>(createEmptyUser());
  const [
    renderTreeUpdatedForUser,
    toggleRenderTreeUpdatedForUser,
  ] = useState<boolean>(false);
  const [currentRenderTrees, setCurrentRenderTrees] = useState<RenderTree[]>(
    []
  );

  const updateRenderTrees = () => {
    if (keycloakUser.isAdmin) {
      getKnotsFromServerForAdmin(axios).then((result) => {
        setCurrentRenderTrees([
          {
            dependsOn: "",
            id: KnotType.ROOT,
            description: "ProModX",
            knotType: KnotType.ROOT,
            name: "ProModX",
            children: result,
          },
        ]);
      });
    } else {
      getKnotsFromServerForUser(user.groupId, axios).then((result) => {
        setCurrentRenderTrees([
          {
            dependsOn: "",
            id: KnotType.ROOT,
            description: "ProModX",
            knotType: KnotType.ROOT,
            name: "ProModX",
            children: result,
          },
        ]);
      });
    }
  };

  useEffect(() => {
    if (keycloak && axios) {
      let tempKeycloakUser: KeycloakUser = createEmptyKeycloakUser();
      keycloak
        .loadUserProfile()
        .then((loadedProfile) => {
          tempKeycloakUser.emailVerified = loadedProfile.emailVerified;
          tempKeycloakUser.username = loadedProfile.username;
          tempKeycloakUser.isAdmin = keycloak.hasRealmRole("ProModX-Admin");
          tempKeycloakUser.isEditor = keycloak.hasRealmRole("ProModX-Editor");
          tempKeycloakUser.isViewer = keycloak.hasRealmRole("ProModX-Viewer");
          tempKeycloakUser.name = loadedProfile.firstName;
        })
        .then(() => {
          setKeycloakUser(tempKeycloakUser);
        });
    }
    // eslint-disable-next-line
  }, [keycloak, axios]);

  useEffect(() => {
    if (
      axios &&
      keycloak &&
      keycloakUser.username !== "" &&
      user.id === undefined
    ) {
      getCurrentUserFromServer(keycloakUser.username!, axios).then(
        (loadedServerUser: User) => {
          if (loadedServerUser.language)
            i18next.changeLanguage(
              loadedServerUser.language.toLocaleLowerCase()
            );
          setUser(loadedServerUser);
        }
      );
    }
  }, [keycloak, axios, keycloakUser, user]);

  useEffect(() => {
    if (keycloakUser && user?.id && axios && !renderTreeUpdatedForUser) {
      updateRenderTrees();
      toggleRenderTreeUpdatedForUser(true);
    }
    // eslint-disable-next-line
  }, [keycloakUser, user, axios]);

  return keycloak ? (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          {keycloak.authenticated ? (
            <Redirect to="/dashboard" />
          ) : (
            () => keycloak.login()
          )}
        </Route>
        <UserContext.Provider value={keycloakUser}>
          <ServerUserContext.Provider
            value={{
              user: user,
              userUpdateFunction: (newUser: User) => setUser(newUser),
            }}
          >
            <RenderTreeContext.Provider
              value={{
                renderTree: currentRenderTrees,
                updateRenderTree: () => updateRenderTrees(),
              }}
            >
              <Route path="/dashboard" exact>
                {keycloak.authenticated ? (
                  <DashboardPage />
                ) : (
                  <Redirect to="/" />
                )}
              </Route>
            </RenderTreeContext.Provider>
          </ServerUserContext.Provider>
        </UserContext.Provider>
      </Switch>
    </BrowserRouter>
  ) : (
    <LoaderComponent />
  );
};

export default App;
