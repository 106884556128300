import { LayoutComponent, RenderTree } from "promodx-component-library";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import KnotDetailView from "../components/KnotDetailView/KnotDetailView";
import SidemenuKnotTree from "../components/SidemenuKnotTree/SidemenuKnotTree";
import { generateEmptyRenderTree } from "../utils/KnotUtil";
import { getCorrectLanguageString } from "../utils/UserUtil";
import { useTranslation } from "react-i18next";
import { ServerUserContext, UserContext } from "./App";
import { isMobile } from "react-device-detect";
import PWAPrompt from "react-ios-pwa-prompt";
import { KnotType } from "../utils/Enum";
import { useKeycloak } from "@react-keycloak/web";

const DashboardPage: React.FC<{}> = () => {
  const [keycloak] = useKeycloak();
  const keycloakUser = useContext(UserContext);
  const { t } = useTranslation();
  const { user } = useContext(ServerUserContext);
  const history = useHistory();
  const location = useLocation() as any;
  const [showMobileMenu, toggleMobileMenu] = useState<boolean>(false);
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState<string>();
  const [currentNode, setCurrentNode] = useState<RenderTree>(
    generateEmptyRenderTree
  );
  const [currentBreadcrumbId, setCurrentBreadcrumbId] = useState<string[]>([
    "ROOT",
  ]);
  const [selectedTreeItem, setSelectedTreeItem] = useState<string[]>([]);

  useEffect(() => {
    if (location?.state !== undefined) {
      setCurrentBreadcrumbId(location.state.breadcrumbId);
      setCurrentBreadcrumb(location.state.breadcrumb);
      setSelectedTreeItem(location?.state.selectedTreeItemId);
      if (location?.state.node) {
        setCurrentNode(location?.state.node);
      }
    } else {
      setCurrentBreadcrumb("");
    }
    // eslint-disable-next-line
  }, [location, location.state]);

  // needed to correctly close the mobile menu on LINIE press
  useEffect(() => {
    if (currentNode?.knotType === KnotType.LINIE) toggleMobileMenu(false);
  }, [currentNode]);

  return (
    <LayoutComponent
      isTestEnvironment={JSON.parse(process.env.REACT_APP_PROMODX_SHOW_TEST!)}
      leftResizable={!isMobile}
      mobileLayout={isMobile}
      showMobileMenu={showMobileMenu}
      toggleMobileMenu={() => toggleMobileMenu(!showMobileMenu)}
      mobileMenuContent={
        <SidemenuKnotTree
          showAsMobile
          updateSelectedTreeItemId={(newIndex: string[]) =>
            setSelectedTreeItem(newIndex)
          }
          key={currentBreadcrumbId.length}
          selectedTreeItemId={selectedTreeItem}
          lastopenend={currentBreadcrumbId}
          onBreadcrumbId={(breadcrumbId: string[]) =>
            setCurrentBreadcrumbId(breadcrumbId)
          }
          isAdmin={keycloakUser.isAdmin}
          isEditor={keycloakUser.isEditor}
          onBreadcrumb={(breadcrumb: string) =>
            setCurrentBreadcrumb(breadcrumb)
          }
          onClick={(node: RenderTree) => setCurrentNode(node)}
        />
      }
      name={keycloakUser.name + " (" + getCorrectLanguageString(user) + ")"}
      versionNumber={t("general.version")}
      homeFunction={() => history.push("/dashboard")}
      leftMenuComponent={
        isMobile ? undefined : (
          <SidemenuKnotTree
            updateSelectedTreeItemId={(newIndex: string[]) =>
              setSelectedTreeItem(newIndex)
            }
            selectedTreeItemId={selectedTreeItem}
            lastopenend={currentBreadcrumbId}
            onBreadcrumbId={(breadcrumbId: string[]) =>
              setCurrentBreadcrumbId(breadcrumbId)
            }
            isAdmin={keycloakUser.isAdmin}
            isEditor={keycloakUser.isEditor}
            onBreadcrumb={(breadcrumb: string) =>
              setCurrentBreadcrumb(breadcrumb)
            }
            onClick={(node: RenderTree) => setCurrentNode(node)}
          />
        )
      }
      logoutFunction={() => keycloak.logout()}
      profileFunction={() => {}}
    >
      <div key={currentBreadcrumb} className="full-height-wrapper">
        <KnotDetailView
          key={currentNode.id}
          knot={currentNode}
          breadcrumb={currentBreadcrumb!}
        />
      </div>
      <PWAPrompt
        // debug
        promptOnVisit={1}
        timesToShow={40}
        copyClosePrompt={t("pwa.ios-prompt-close")}
        copyTitle={t("pwa.ios-prompt-title")}
        copyBody={t("pwa.ios-prompt-body")}
        copyShareButtonLabel={t("pwa.ios-prompt-share-button")}
        copyAddHomeButtonLabel={t("pwa.ios-prompt-home-button")}
        // permanentlyHideOnDismiss={false}
      />
    </LayoutComponent>
  );
};

export default DashboardPage;
