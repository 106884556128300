import { AxiosInstance } from "axios";
import { User } from "./Interface";

/**
 * get the current User for Profile
 *
 * @param userId
 * @param axios
 */
export const getCurrentUserFromServer = async (
  userId: string,
  axios: AxiosInstance
): Promise<User> => {
  return axios
    .get("/user/", { params: { userId: userId } })
    .then((result) => result.data);
};

/**
 * Helper for the correct language string in layout
 *
 * @param user
 */
export const getCorrectLanguageString = (user: User): string => {
  if (user.language) {
    return user.language;
  } else {
    return navigator.language.includes("de")
      ? "DE"
      : navigator.language.includes("en")
      ? "EN"
      : "unknown";
  }
};
