import { PopupComponent, RenderTree } from "promodx-component-library";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ProModXImage from "../../assets/images/promodx.png";
import { KnotType } from "../../utils/Enum";
import PowerWall from "../PowerWall/PowerWall";
import "./KnotDetailViewStyle.scss";

interface KnotDetailViewProps {
  knot: RenderTree;
  breadcrumb: string;
}

const KnotDetailView: React.FC<KnotDetailViewProps> = ({
  knot,
  breadcrumb,
}) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <>
      <div className="knot-detail-view-wrapper">
        <div className="headline-separator">
          <div
            className="breadcrumb-headline-wrapper"
            dangerouslySetInnerHTML={{
              __html: breadcrumb
                ? breadcrumb.replace(
                    /,|\\/g,
                    ' <div class="breadcrumb-headline-arrow"></div> '
                  )
                : "ProModX",
            }}
          />
        </div>
        {knot.knotType !== KnotType.LINIE && (
          <div className="knot-detail-image-wrapper">
            <img alt="ProModX" title="ProModX" src={ProModXImage} />
          </div>
        )}
        {knot.knotType === KnotType.LINIE && <PowerWall selectedKnot={knot} />}
      </div>
      <PopupComponent
        show={showPopup}
        hideFunction={() => setShowPopup(false)}
        buttonFunction={() => setShowPopup(false)}
        title={t("general.popup.error.title")}
        buttonText={t("general.popup.error.button")}
      >
        <div className="error-popup-component-text-wrapper">
          <Trans i18nKey="general.popup.error.text">
            Es ist ein Fehler aufgetreten. Bitte melden Sie sich bei uns.
          </Trans>
        </div>
      </PopupComponent>
    </>
  );
};

export default KnotDetailView;
