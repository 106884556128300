import { RenderTree, TreeComponent } from "promodx-component-library";
import React, { useContext } from "react";
import { RenderTreeContext } from "../../pages/App";
import { KnotType } from "../../utils/Enum";
import "./SidemenuKnotTreeStyle.scss";

interface SidemenuKnotTreeProps {
  isAdmin: boolean;
  isEditor: boolean;
  showAsMobile?: boolean;
  onClick: Function;
  onBreadcrumb: Function;
  onBreadcrumbId: Function;
  lastopenend?: string[];
  updateSelectedTreeItemId: Function;
  selectedTreeItemId: string[];
}

const SidemenuKnotTree: React.FC<SidemenuKnotTreeProps> = ({
  isAdmin,
  isEditor,
  onClick,
  onBreadcrumb,
  onBreadcrumbId,
  lastopenend,
  updateSelectedTreeItemId,
  selectedTreeItemId,
  showAsMobile,
}) => {
  const { renderTree } = useContext(RenderTreeContext);

  return (
    <div className="sidemenu-knot-tree-wrapper">
      <div className="sidemenu-knot-tree-tree">
        {renderTree &&
          renderTree.map((currentRenderTree, index) => {
            return (
              <TreeComponent
                hideStations
                mobileLayout={showAsMobile}
                onIdBreadcrumb={(breadcrumbId: string[]) => {
                  if (breadcrumbId !== undefined) {
                    onBreadcrumbId(breadcrumbId);
                  }
                }}
                defaultExpand={lastopenend ? lastopenend : [KnotType.ROOT]}
                showExpandCollapseButtons
                key={index}
                onBreadcrumb={(breadcrumb: string) => {
                  onBreadcrumb(breadcrumb);
                }}
                isAdmin={isAdmin}
                getSelectedIndex={(newIndex: string[]) =>
                  updateSelectedTreeItemId(newIndex)
                }
                selectedIndex={selectedTreeItemId}
                isEditor={isEditor}
                treeData={currentRenderTree}
                emptyTreeText={"no tree data"}
                onClick={(node: RenderTree) => onClick(node)}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SidemenuKnotTree;
